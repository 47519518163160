import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faFacebook,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

import TopBar from '../../images/footer-top-bar.png'
import TopBg from '../../images/footer-top-bg.png'
import BottomBg from '../../images/footer-bottom-bg.png'

import Container from './container'
import SubscribeForm from './subscribe-form'

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <TopContainer>
          <TopSection isFirst>
            <h2>Misión</h2>
            <p>
              Construir un espacio de comunicación educativa que sea amigable,
              claro y con rigor científico para difundir ideas frescas y
              artículos interesantes sobre Educación e Infancia, abriendo
              espacios de diálogo e intercambio.
            </p>
          </TopSection>
          <TopSection>
            <h2>Visión</h2>
            <p>
              Dar el lugar y la importancia que merece la Educación como agente
              de desarrollo social, revalorar la infancia como edad propicia
              para establecer las bases sólidas del desarrollo general de una
              persona.
            </p>
          </TopSection>
          <TopSection>
            <h2>Redes Sociales</h2>
            <SocialMedia>
              <SocialMediaButton
                href="https://instagram.com/edu_kapuli"
                target="_blank"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </SocialMediaButton>
              <SocialMediaButton
                href="https://www.facebook.com/edukapuli"
                target="_blank"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </SocialMediaButton>
              <SocialMediaButton
                href="https://www.linkedin.com/in/sole-aguilar"
                target="_blank"
                aria-label="Linkedin"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </SocialMediaButton>
            </SocialMedia>
          </TopSection>
          <TopSection>
            <SubscribeForm />
          </TopSection>
        </TopContainer>
      </Container>
      <BottomContainer>
        <Container>
          Edukapulí © {new Date().getFullYear()}. Todos los derechos reservados.
        </Container>
      </BottomContainer>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  position: relative;
  background-color: #4559b3;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: center center;
  background-image: url(${TopBg});
  background-size: inherit;
  font-size: 15px;
  color: #fff;

  ${props => props.theme.mq.desktop} {
    font-size: 16px;
  }

  p {
    margin: 0 0 15px;

    ${props => props.theme.mq.desktop} {
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #fff;

    ${props => props.theme.mq.desktop} {
      margin-bottom: 30px;
    }
  }

  &:before {
    height: 70px;
    top: -70px;
    background-image: url(${TopBar});
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const TopContainer = styled.div`
  padding-bottom: 30px;

  ${props => props.theme.mq.desktop} {
    display: flex;
    margin-right: -25px;
    margin-left: -25px;
    padding-top: 65px;
    padding-bottom: 65px;

    & > * {
      width: 25%;
      padding: 0 25px;
    }
  }
`

const TopSection = styled.div`
  ${props => props.theme.mq.until.desktop} {
    margin-top: ${props => (props.isFirst ? 0 : '50px')};
  }
`

const BottomContainer = styled.div`
  background-color: #3e55ac;
  position: relative;
  margin-top: 50px;
  padding: 10px 0 30px 0;
  color: #fff;

  &:after {
    height: 73px;
    top: -73px;
    z-index: 2;
    background: url(${BottomBg});
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-position: left;
    background-repeat: repeat-x;
  }
`

const SocialMedia = styled.div`
  display: flex;

  a {
    & + a {
      margin-left: 15px;
    }
  }
`

const SocialMediaButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  font-family: ${props => props.theme.seccondaryFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.eduPurple};
    transform: translateY(-5px);

    svg {
      color: #fff;
    }
  }

  svg {
    color: #4559b3;
    transition: color 0.2s ease-in-out;
  }
`

export default Footer
