import React, { useState, useRef, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const PrimaryNavigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const menuRef = useRef(null)

  const { categories } = useStaticQuery(graphql`
    query {
      categories: allContentfulCategory(sort: { order: ASC, fields: order }) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  useEffect(() => {
    const navEl = menuRef.current

    if (navEl) {
      if (mobileMenuOpen) {
        const listHeight = navEl.querySelector('ul')
        navEl.style.height = `${listHeight.offsetHeight}px`
      } else {
        navEl.style.height = 0
      }
    }
  }, [mobileMenuOpen, menuRef])

  return (
    <>
      <Toggle
        aria-label={mobileMenuOpen ? 'Cerrar Menu' : 'Abrir Menu'}
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        <ToggleIcon className={mobileMenuOpen ? 'open' : 'closed'}>
          <span />
          <span />
          <span />
        </ToggleIcon>
      </Toggle>
      <Navigation ref={menuRef}>
        <ul>
          <li>
            <Link to="/" activeClassName="active">
              Inicio
            </Link>
          </li>
          {categories.nodes.map(category => (
            <li key={category.slug}>
              <Link to={`/category/${category.slug}`}>{category.name}</Link>
            </li>
          ))}
        </ul>
      </Navigation>
    </>
  )
}

const Toggle = styled.button`
  appearance: none;
  height: 35px;
  margin: 0;
  padding: 10px;
  text-decoration: none;
  border: 0;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 6px 5px 30px 0px rgb(0 0 0 / 12%);

  ${props => props.theme.mq.desktop} {
    display: none;
  }
`

const ToggleIcon = styled.span`
  display: block;
  position: relative;
  width: 22px;
  height: 17px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${props => props.theme.colors.primaryTextColor};
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform-origin: left center;
    transition: all 0.25s ease;

    &:nth-child(2) {
      top: 8px;
    }

    &:nth-child(3) {
      top: 15px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0;
        left: 3px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 15px;
        left: 3px;
      }
    }
  }
`

const Navigation = styled.nav`
  ${props => props.theme.mq.until.desktop} {
    overflow: hidden;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 4px 18px rgb(0 0 0 / 10%);
    transition: height 0.2s ease-in-out;
  }
  ${props => props.theme.mq.desktop} {
    height: auto !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 5px 0;

    ${props => props.theme.mq.desktop} {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }

  li {
    & + li {
      ${props => props.theme.mq.desktop} {
        margin-left: 5px;
      }

      ${props => props.theme.mq.until.desktop} {
        border-top: #e8e8e8 solid 1px;
      }
    }
  }

  a {
    font-family: ${props => props.theme.seccondaryFont};
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-decoration: none;
    color: ${props => props.theme.colors.primaryTextColor};

    ${props => props.theme.mq.until.desktop} {
      display: block;
      padding: 10px 15px;
    }

    ${props => props.theme.mq.desktop} {
      height: 50px;
      padding: 12px 25px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      border-radius: 6px;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;

      &.active {
        background-color: ${props => props.theme.colors.eduPurple};
        box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
        color: #fff;
        pointer-events: none;
      }

      &:hover,
      &:active {
        background-color: ${props => props.theme.colors.eduBlue};
        box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
        color: #fff;
      }
    }
  }
`

export default PrimaryNavigation
