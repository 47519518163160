const breakpoints = {
  mobile: 360,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1280,
  wide: 1600
}

const theme = {
  primaryFont: `'Mulish', sans-serif`,
  seccondaryFont: `'Baloo 2', cursive`,
  colors: {
    primaryTextColor: '#333663',
    seccondaryTextColor: '#666666',
    eduRed: '#C44151',
    eduBlue: '#4F93AE',
    eduGreen: '#9DA73A',
    eduYellow: '#DCB100',
    eduPurple: '#CF7DD1',
    eduOrange: '#FD6658',
    background: '#FDF5F2'
  },
  mq: {
    mobile: `@media (min-width: ${breakpoints.mobile}px)`,
    tablet: `@media (min-width: ${breakpoints.tablet}px)`,
    tabletLandscape: `@media (min-width: ${breakpoints.tabletLandscape}px)`,
    desktop: `@media (min-width: ${breakpoints.desktop}px)`,
    wide: `@media (min-width: ${breakpoints.wide}px)`,
    until: {
      mobile: `@media (max-width: ${breakpoints.mobile - 1}px)`,
      tablet: `@media (max-width: ${breakpoints.tablet - 1}px)`,
      tabletLandscape: `@media (max-width: ${
        breakpoints.tabletLandscape - 1
      }px)`,
      desktop: `@media (max-width: ${breakpoints.desktop - 1}px)`,
      wide: `@media (max-width: ${breakpoints.wide - 1}px)`
    }
  },
  containerMaxWidth: '1840px',
  containerSmallMaxWidth: '1380px',
  containerPaddingMobile: '20px',
  containerPaddingDesktop: '80px',
  containerPaddingWide: '120px'
}

export default theme
