import styled from 'styled-components'

const Container = styled.div`
  max-width: ${props => props.theme.containerMaxWidth};
  margin: 0 auto;
  padding: 0 ${props => props.theme.containerPaddingMobile};

  ${props => props.theme.mq.desktop} {
    padding: 0 ${props => props.theme.containerPaddingDesktop};
  }

  ${props => props.theme.mq.wide} {
    padding: 0 ${props => props.theme.containerPaddingWide};
  }
`

export default Container
