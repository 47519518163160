import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Container from './container'
import PrimaryNavigation from './primary-navigation'

const Header = () => {
  const headerEl = useRef(null)

  useEffect(() => {
    if (!headerEl.current) return

    const isSticky = e => {
      window.scrollY > 0
        ? headerEl.current.classList.add('is-sticky')
        : headerEl.current.classList.remove('is-sticky')
    }

    isSticky()
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [headerEl])

  return (
    <Wrapper ref={headerEl}>
      <Inner>
        <Logo to="/">
          <StaticImage
            alt="Edukapuli"
            src="../../images/logo.png"
            width={264}
            placeholder="none"
          />
        </Logo>
        <PrimaryNavigation />
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 66px;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  ${props => props.theme.mq.desktop} {
    height: 76px;
  }

  ${props => props.theme.mq.until.desktop} {
    background-color: #fff;
  }

  &.is-sticky {
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
`

const Inner = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${props => props.theme.mq.until.desktop} {
    justify-content: space-between;
  }
`

const Logo = styled(Link)`
  margin-right: 80px;
  .gatsby-image-wrapper {
    img {
      height: 30px;

      ${props => props.theme.mq.desktop} {
        height: 40px;
      }
    }
  }
`

export default Header
