import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyles = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box !important;

    &:before,
    &:after {
      box-sizing: border-box;
    }
  }

  body {
    font-family: ${props => props.theme.primaryFont};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${props => props.theme.colors.seccondaryTextColor};

    ${props => props.theme.mq.desktop} {
      font-size: 18px;
      line-height: 26px;
    }
  }

  h1, h2, h3 {
    font-family: ${props => props.theme.seccondaryFont};
    color: ${props => props.theme.colors.primaryTextColor};
    margin-top: 0 !important;
    margin-bottom: 20px;
  }

  h1 {
    line-height: 53px;
    font-size: 46px;
    font-weight: 800;

    ${props => props.theme.mq.desktop} {
      font-size: 80px;
      line-height: 90px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 800;

    ${props => props.theme.mq.desktop} {
      font-size: 46px;
      line-height: 56px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
  }

  a {
    color: ${props => props.theme.colors.eduRed};
  }
`
export default GlobalStyles
