import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const BlogCard = ({ slug, heroImage, title, publishDate }) => (
  <Card to={`/${slug}`}>
    <ImageContainer>
      <GatsbyImage
        alt={heroImage.description}
        image={heroImage.gatsbyImageData}
      />
      <PublishDate>
        <FontAwesomeIcon icon={faCalendarDay} /> {publishDate}
      </PublishDate>
    </ImageContainer>

    <Title>{title}</Title>
    <ReadMore>
      Leer mas <FontAwesomeIcon icon={faAngleRight} />
    </ReadMore>
  </Card>
)

const Card = styled(Link)`
  display: block;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 10px 10px 30px 10px;
  text-decoration: none;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  text-align: center;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
`

const ImageContainer = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 75%;
    border-radius: 10px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const PublishDate = styled.span`
  background: ${props => props.theme.colors.eduBlue};
  border-radius: 6px;
  position: relative;
  top: -25px;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  font-size: 14px;
  font-weight: 600;
  color: #fff;

  svg {
    margin-right: 5px;
  }
`

const Title = styled.h3`
  color: ${props => props.theme.colors.primaryTextColor};
  margin-bottom: 10px;
`

const ReadMore = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: ${props => props.theme.seccondaryFont};
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: ${props => props.theme.colors.eduRed};

  svg {
    margin-left: 5px;
  }
`

export default BlogCard
