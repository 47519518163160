import React, { useState } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const SubscribeForm = () => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  const _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email)

    setStatus(result.result)
  }

  return (
    <div>
      <h2>Subscríbete</h2>
      {status !== 'success' ? (
        <div>
          <p>Recibe cada artículo nuevo en tu correo electrónico.</p>
          <NewsletterForm onSubmit={_handleSubmit}>
            <NewsletterInput
              type="email"
              placeholder="Tu correo electrónico"
              aria-label="Correo electrónico"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <NewsletterButton type="submit" aria-label="Enviar">
              <FontAwesomeIcon icon={faEnvelope} />
            </NewsletterButton>
          </NewsletterForm>
          {status === 'error' && (
            <NewsletterError>
              Ocurrio un error. Intente más tarde
            </NewsletterError>
          )}
        </div>
      ) : (
        <p>Subscrito exitosamente.</p>
      )}
    </div>
  )
}

const NewsletterForm = styled.form`
  position: relative;
`

const NewsletterInput = styled.input`
  display: block;
  width: 100%;
  height: 55px;
  font-size: 16px;
  color: #757575;
  line-height: normal;
  padding: 0px 15px;
  font-weight: 400;
  background: #ffffff;
  border: 0;
  border-radius: 6px;
`

const NewsletterButton = styled.button`
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  padding: 0;
  font-family: ${props => props.theme.seccondaryFont};
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-decoration: none;
  background-color: ${props => props.theme.colors.eduYellow};
  border: 0;
  border-radius: 6px;
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.eduPurple};
  }
`

const NewsletterError = styled.p`
  margin: 5px 0 0 !important;
  font-size: 14px;
  color: #f2cbcb;
`

export default SubscribeForm
