import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from '../../utils/theme'

import Seo from './seo'
import GlobalStyles from './global-styles'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Seo />
      <Header />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
